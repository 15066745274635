<template>
  <div>

    <b-alert
      v-if="statusAprovacaoCampanha === 'AGUARDANDO_IMAGEM' && !carregamentoApi"
      variant="warning"
      show
    >
      <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        <span><b>Sua audição está em rascunho:</b></span><br>
        <span>Estamos aguardando a imagem para finalizar o envio da audição</span>
      </div>
    </b-alert>

    <b-alert
      v-else-if="!carregamentoApi"
      variant="danger"
      show
    >
      <div class="alert-body">
        <feather-icon
          icon="AlertTriangleIcon"
          class="mr-50"
        />
        <span><b>Sua audição foi reprovada.</b></span><br>
        <span>{{ motivoStatus }}. Edite e reenvie.</span>
      </div>
    </b-alert>
    

    <campanha-editar-geral v-if="statusAprovacaoCampanha === 'REPROVADO_GERAL'" :campanha="campanha"></campanha-editar-geral>
    <campanha-editar-infos v-if="statusAprovacaoCampanha === 'REPROVADO_INFORMACOES'" :campanha="campanha"></campanha-editar-infos>
    <campanha-editar-imagem v-if="statusAprovacaoCampanha === 'REPROVADO_IMAGEM' || statusAprovacaoCampanha === 'AGUARDANDO_IMAGEM'" :campanha="campanha"></campanha-editar-imagem>

  </div>
</template>

<script>
import router from '@/router'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormFile,
  BModal,
  VBModal,
  BButton,
  BCardText,
  BSpinner,
  BListGroup, 
  BListGroupItem,
  BAlert,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

import axios from 'axios'

import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

import CampanhaEditarGeral from './CampanhaEditarGeral.vue'
import CampanhaEditarInfos from './CampanhaEditarInfos.vue'
import CampanhaEditarImagem from './CampanhaEditarImagem.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BModal,
    BButton,
    BCardText,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components

    CampanhaEditarGeral,
    CampanhaEditarInfos,
    CampanhaEditarImagem,

  },
  data() {
    return {
      jwtConfig: { ...jwtDefaultConfig },
      carregamentoApi: false,
      campanha: [],
      statusAprovacaoCampanha: null,
      motivoStatus: null,
    }
  },
  created() {
  
    // PUXAR DADOS DO HIT
    this.campanhaId()
    
  },
  methods: {

    campanhaId() {
      this.carregamentoApi = true

      useJwt.campanhaCompradorId({
        id: router.currentRoute.params.id,
      })
        .then(response => {
          this.campanha = response.data
          // this.hitNome = response.data.nomeComposicao
          // this.hitCompositores = response.data.nomeCompositores
          // this.hitValorLiberacao = response.data.valorLiberacao
          // this.hitValorExclusividade = response.data.valorExclusividade
          // this.hitTemas = response.data.listaTemas.map(tema => tema.id)
          // this.hitEstilos = response.data.listaEstilos.map(estilo => estilo.id)
          // this.hitLetra = response.data.letra
          // this.hitId = response.data.id
          this.motivoStatus = response.data.motivoStatus
          this.statusAprovacaoCampanha = response.data.statusAprovacaoCampanha

        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    
  }

}
</script>
