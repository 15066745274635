<template>
  <div>

    <form-wizard
      color="#000"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Finalizar"
      next-button-text="Próximo"
      back-button-text="Voltar"
      class="mb-3"
      @on-complete="formSubmitted"
      @on-change="alterandoTab"
    >

      <!-- arquivo -->
      <tab-content
        title="Capa"
        :before-change="validationCampanhaCapa"
      >
        <validation-observer
          ref="campanhaCapaRules"
          tag="form"
        >
          <b-row>

            <b-col
              md="6"
              class="mb-2"
            >
              <h5 class="mb-0">
                Imagem de Capa
              </h5>
              <small class="text-muted">Carregue a imagem de capa da sua campanha.</small>
              <br><br>
              <h6><strong>Tamanho:</strong> 800 x 450</h6>
              <h6><strong>Formatos:</strong> png, jpeg, jpg</h6>
              <!-- <h1><i class="fa "></i>{{ campanhaId }}</h1> -->

              <b-form-group
                label="Arquivo"
                label-for="campanhaCapa"
              >
                <validation-provider
                  #default="{ errors }"
                  name="campanhaCapa"
                  rules="required"
                >
                  <b-form-file
                    id="campanhaCapa"
                    v-model="campanhaCapa"
                    :state="Boolean(campanhaCapa)"
                    accept="image/jpeg, image/png, image/jpg"
                    placeholder="Faça o upload da imagem"
                    @change="teste($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>

            <b-col
              md="4"
            >

              <h4>Prévia</h4>
              <p>É assim que os compositores irão ver a sua campanha</p>
              <b-card
                :img-src="campanhaCapaPreview"
                img-top
                :title="campanhaNome"
                :sub-title="campanhaData"
              >

                <!-- <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  block
                >

                  Participar
                </b-button> -->
              </b-card>

            </b-col>

          </b-row>

        </validation-observer>
      </tab-content>


      <template
        slot="footer"
        slot-scope="props"
      >
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            :style="props.fillButtonStyle"
            @click.native="props.prevTab()"
          >
            Voltar
          </wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
          >
            Próximo
          </wizard-button>

          <wizard-button
            v-else
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
          >
            {{ props.isLastStep ? 'Finalizar' : 'Próximo' }}
          </wizard-button>
        </div>
      </template>

    </form-wizard>

    <!-- basic modal -->
    <b-modal
      id="campanhaCarregando"
      ref="campanhaCarregando"
      centered
      title="Enviando Imagem"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-text class="text-center p-2">
        <strong>A imagem está sendo carregada.</strong><br> Não feche e não atualize essa página.<br>
        <b-spinner
          class="mt-2"
          variant="primary"
        />
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import {
  FormWizard, TabContent, WizardStep, WizardButton,
} from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormFile,
  BModal,
  VBModal,
  BButton,
  BCardText,
  BSpinner,
  BCard,
  BCardTitle,
  BCardBody,
  BCardSubTitle,
  BLink,

} from 'bootstrap-vue'
import { required, email } from '@validations'

import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

import useJwt from '@/auth/jwt/useJwt'

import { Money } from 'v-money'

import axios from 'axios'

import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      campanhaNome: {
        required: 'O nome da campanha é obrigatório.',
      },
      campanhaDescricao: {
        required: 'A descrição da campanha é obrigatória.',
      },
      campanhaData: {
        required: 'A data de validade da campanha é obrigatória.',
      },
      hitGuia: {
        required: 'O upload do arquivo da guia é obrigatório.',
      },
    },
  },
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    WizardButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    vSelect,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BModal,
    BButton,
    BCardText,
    BCard,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Money,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      configCampanhaData: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d\\\\T02:59:00.000\\\Z',
        locale: Portuguese, // locale for this instance only
      },
      campanhaId: '',
      campanhaNome: '',
      campanhaDescricao: '',
      campanhaData: '',
      campanhaCapa: [],
      campanhaCapaPreview: require('@/assets/images/previas/campanha.png'),
      hitArquivo: '',
      jwtConfig: { ...jwtDefaultConfig },
    }
  },
  created() {

    // PUXAR DADOS DA CAMPANHA
    this.campanhaCompradorId()

  },
  props: {
    campanha: {
      type: Object,
      required: true,
    },
  },
  methods: {

    campanhaCompradorId() {

    const campanha = this.campanha
    
    this.campanhaData = campanha.validade
    this.campanhaId = campanha.id
    },

    alterandoTab: function (activeStep, nextStep) {

      const wizardNav = document.querySelector('.wizard-nav'); // selecione o elemento ul que contém as guias
      const numSteps = wizardNav.querySelectorAll('li').length; // conte o número de elementos li dentro do ul

      const guiaAtual = nextStep + 1

      if (numSteps == guiaAtual) {

        const navList = document.querySelectorAll('.wizard-nav-pills li');

        navList.forEach((li, index) => {
          if (index !== navList.length - 1) {
            li.style.display = 'none';
          } else {
            const a = li.querySelector('a');
            if (a) {
              const style = document.createElement('style');
              style.innerHTML = `a::before { display: none; }`;
              document.head.appendChild(style);
            }
          }
        });
        
      }
      
    },

    teste(event) {
      const file = event.target.files[0]

      const formData = new FormData()

      formData.append('file', file)

      this.campanhaCapaPreview = URL.createObjectURL(file)

      // console.log( formData );return false;
    },

    getFormatarData(dataBanco) {
      var novaData = dataBanco.split('T')
      var novaData = novaData[0].split('-')
      var novaData = `${novaData[2]}/${novaData[1]}/${novaData[0]}`

      return novaData
    },

    formSubmitted() {
      // console.log( this.hitGuia.name );

      // MODAL - UPLOAD CARREGANDO - ABRIR
      this.$refs.campanhaCarregando.show()

      // console.log( this.hitTemas );

      // console.log( this.hitGuia );

      const formData = new FormData()
      formData.append('arquivo', this.campanhaCapa)

      // console.log( formData );return false;

      // API - CADASTRAR HIT

      axios.post(`${this.jwtConfig.campanhaCapaEditarUploadEndpoint}/${this.campanhaId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          // console.log( response );return false;

          this.$swal({
            title: 'Audição Editada!',
            text: 'Sua audição está em análise, assim que for aprovada você receberá uma notificação.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'campanhas' })
            } else {
              this.$router.push({ name: 'campanhas' })
            }
            // console.log( result );
          })
        })
        .catch(error => {
          // console.log( error.response.data.message );

          this.$swal({
            title: 'Ops! Aconteceu um erro.',
            text: error.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .finally(response => {
          // MODAL - HIT CARREGANDO - FECHAR
          this.$refs.campanhaCarregando.hide()

          console.log(response); return false
        })

      // API - CADASTRAR HIT

      // this.$toast({
      // component: ToastificationContent,
      // props: {
      //     title: 'Form Submitted',
      //     icon: 'EditIcon',
      //     variant: 'success',
      // },
      // })
    },
    validationCampanhaInformacoes() {
      return new Promise((resolve, reject) => {
        this.$refs.campanhaInformacoesRules.validate().then(success => {
          if (success) {
            // alert('chegou até aqui');
            resolve(true)
            // return false;

            // API - CADASTRAR CAMPANHA

            useJwt.campanhaEditar({
              id: this.campanhaId,
              nome: this.campanhaNome,
              descricao: this.campanhaDescricao,
              validade: this.campanhaData,
            })
              .then(response => {
              // console.log( response );

                // PASSAR O CAMPANHA ID PARA A ABA DE UPLOAD
                this.campanhaId = response.data

                this.$swal({
                  title: 'Informações Editadas!',
                  text: 'Sua audição está em análise, assim que for aprovada você receberá uma notificação.',
                  icon: 'success',
                  // confirmButtonText: 'Fazer Upload da Imagem',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.isConfirmed) {
                    this.$router.push({ name: 'campanha-pendencias' })
                  } else {
                    this.$router.push({ name: 'campanha-pendencias' })
                  }
                  // console.log( result );
                })
              })
              .catch(error => {
              // console.log( error.response.data.message );

                this.$swal({
                  title: 'Ops! Aconteceu um erro.',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .finally(() => {

                // // PASSA PARA PRÓXIMA GUIA
                // resolve(true)

                // // MODAL - HIT CARREGANDO - FECHAR
                // this.$refs['hitCarregando'].hide();

              })

            // API - CADASTRAR HIT
          } else {
            reject()
          }
        })
      })
    },
    validationCampanhaCapa() {
      return new Promise((resolve, reject) => {
        this.$refs.campanhaCapaRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Procurar';
}

[dir=ltr] .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
  border-right-width: 1px !important;
}

[dir] .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: none;
}

@media (max-width: 767px) {
  ul.wizard-nav.wizard-nav-pills li {
    display: none !important;
  }
  
  ul.wizard-nav.wizard-nav-pills li.active {
    display: block !important;
  }
}

</style>
