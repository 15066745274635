var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#000","title":null,"subtitle":null,"shape":"square","finish-button-text":"Finalizar","next-button-text":"Próximo","back-button-text":"Voltar"},on:{"on-complete":_vm.formSubmitted,"on-change":_vm.alterandoTab},scopedSlots:_vm._u([{key:"footer",fn:function(props){return [_c('div',{staticClass:"wizard-footer-left"},[(props.activeTabIndex > 0 && !props.isLastStep)?_c('wizard-button',{style:(props.fillButtonStyle),nativeOn:{"click":function($event){return props.prevTab()}}},[_vm._v(" Voltar ")]):_vm._e()],1),_c('div',{staticClass:"wizard-footer-right"},[(!props.isLastStep)?_c('wizard-button',{staticClass:"wizard-footer-right",style:(props.fillButtonStyle),nativeOn:{"click":function($event){return props.nextTab()}}},[_vm._v(" Próximo ")]):_c('wizard-button',{staticClass:"wizard-footer-right finish-button",style:(props.fillButtonStyle),nativeOn:{"click":function($event){return props.nextTab()}}},[_vm._v(" "+_vm._s(props.isLastStep ? 'Finalizar' : 'Próximo')+" ")])],1)]}}])},[_c('tab-content',{attrs:{"title":"Informações da Audição","before-change":_vm.validationCampanhaInformacoes}},[_c('validation-observer',{ref:"campanhaInformacoesRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Informações da Audição ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Insira as informações ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome da Audição","label-for":"campanhaNome"}},[_c('validation-provider',{attrs:{"name":"campanhaNome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"campanhaNome","state":errors.length > 0 ? false:null,"placeholder":"Digite o nome da sua audição"},model:{value:(_vm.campanhaNome),callback:function ($$v) {_vm.campanhaNome=$$v},expression:"campanhaNome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Descrição","label-for":"campanhaDescricao"}},[_c('validation-provider',{attrs:{"name":"campanhaDescricao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"campanhaDescricao","state":errors.length > 0 ? false:null,"placeholder":"Digite e explique o que é sua audição"},model:{value:(_vm.campanhaDescricao),callback:function ($$v) {_vm.campanhaDescricao=$$v},expression:"campanhaDescricao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Quando encerra?","label-for":"campanhaData"}},[_c('validation-provider',{attrs:{"name":"campanhaData","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"campanhaData","config":_vm.configCampanhaData,"state":errors.length > 0 ? false:null},model:{value:(_vm.campanhaData),callback:function ($$v) {_vm.campanhaData=$$v},expression:"campanhaData"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Capa","before-change":_vm.validationCampanhaCapa}},[_c('validation-observer',{ref:"campanhaCapaRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Imagem de Capa ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Carregue a imagem de capa da sua campanha.")]),_c('br'),_c('br'),_c('h6',[_c('strong',[_vm._v("Tamanho:")]),_vm._v(" 800 x 450")]),_c('h6',[_c('strong',[_vm._v("Formatos:")]),_vm._v(" png, jpeg, jpg")]),_c('b-form-group',{attrs:{"label":"Arquivo","label-for":"campanhaCapa"}},[_c('validation-provider',{attrs:{"name":"campanhaCapa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"campanhaCapa","state":Boolean(_vm.campanhaCapa),"accept":"image/jpeg, image/png, image/jpg","placeholder":"Faça o upload da imagem"},on:{"change":function($event){return _vm.teste($event)}},model:{value:(_vm.campanhaCapa),callback:function ($$v) {_vm.campanhaCapa=$$v},expression:"campanhaCapa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('h4',[_vm._v("Prévia")]),_c('p',[_vm._v("É assim que os compositores irão ver a sua campanha")]),_c('b-card',{attrs:{"img-src":_vm.campanhaCapaPreview,"img-top":"","title":_vm.campanhaNome,"sub-title":_vm.campanhaData}})],1)],1)],1)],1)],1),_c('b-modal',{ref:"campanhaCarregando",attrs:{"id":"campanhaCarregando","centered":"","title":"Enviando Imagem","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":""}},[_c('b-card-text',{staticClass:"text-center p-2"},[_c('strong',[_vm._v("A imagem está sendo carregada.")]),_c('br'),_vm._v(" Não feche e não atualize essa página."),_c('br'),_c('b-spinner',{staticClass:"mt-2",attrs:{"variant":"primary"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }